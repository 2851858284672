import React, { useState, useEffect } from "react"

import LogoFiec from "../../assets/images/fiec-logo.png"
import { Background, Container, Countdown, Logo, Text } from "./style"

function AvisoManutencao({timeRemaining}) {

  return (
    <Background>
      <Container>
        <Logo src={LogoFiec} alt="Logo FIEC"/>
        <div>
          <Text>Sistema em Manutenção.</Text>
          <Text>Estamos trabalhando para melhorar sua experiência!</Text>
          <Text>
            Retornaremos em:
            <Countdown>
              {timeRemaining.days}d {timeRemaining.hours}h {timeRemaining.minutes}m {timeRemaining.seconds}s
            </Countdown>
          </Text>
        </div>
      </Container>
    </Background>
  )
}

export default AvisoManutencao
