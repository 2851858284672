import styled from 'styled-components';

export const Background = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
`;

export const Container = styled.div`
  width: 50vw;
  min-height: 20vh;
  margin: 20px;
  padding: 35px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.6);

  @media (max-width: 1024px) {
    width: 70vw;
    padding: 30px 15px;
  }

  @media (max-width: 768px) {
    width: 80vw;
    padding: 25px 10px;
  }

  @media (max-width: 480px) {
    width: 90vw;
    padding: 20px 5px;
  }
`;

export const Logo = styled.img`
  height: 60px;
  margin-left: -25px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    height: 50px;
    margin-left: 0;
  }

  @media (max-width: 480px) {
    height: 40px;
  }
`;

export const Text = styled.p`
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;

  @media (max-width: 1024px) {
    font-size: 1.8rem;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

export const Countdown = styled.span`
  color: #d9534f;
  white-space: nowrap;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.02);
  }

  @media (max-width: 1024px) {
    font-size: 1.6rem;
  }

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;
